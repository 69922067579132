
































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'About',
  components: {},

  setup() {
    const { content } = useGetters(['content'])
    const components = {
      ctaGallery: () => import('@/components/cta/Gallery.vue'),
      ctaJobs: () => import('@/components/cta/Jobs.vue'),
      ctaText: () => import('@/components/cta/Text.vue'),
      ctaType: () => import('@/components/cta/PreEncoded.vue'),
      gallery: () => import('@/components/flexible/Gallery.vue'),
      galleryWtext: () => import('@/components/anders-wonen/Gallery.vue'),
      highlighted2: () =>
        import('@/components/flexible/HighlightedSection.vue'),
      iconsList: () => import('@/components/flexible/IconList.vue'),
      keyFacts: () => import('@/components/flexible/KeyList.vue'),
      keyFigures: () => import('@/components/flexible/KeyList.vue'),
      miniGallery: () => import('@/components/flexible/Gallery.vue'),
      picture: () => import('@/components/flexible/Picture.vue'),
      pictureTextDuo: () => import('@/components/flexible/DuoPicture.vue'),
      quote: () => import('@/components/flexible/Quote.vue'),
      testimonials: () => import('@/components/flexible/Testimonials.vue'),
      timeline: () => import('@/components/flexible/Timeline.vue'),
      video: () => import('@/components/anders-wonen/Gallery.vue'),
      wysiwyg: () => import('@/components/g/Wysiwyg.vue'),
    }

    return {
      content,
      components,
    }
  },
})
